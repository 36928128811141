import React from 'react';

import { BlogDataStyles } from './styles.jsx';

interface Props {
  Category?: string;
  Author?: object;
  Date?: string;
  ReadTime?: string;
}

export const BlogData = ({ Category = '', Author, Date, ReadTime, ...props }: Props) => {
  return (
    <BlogDataStyles className={'Component-BlogData'} {...props}>
      {Category && <span className={'category'}>{Category}</span>}
      {Author && (
        <>
          <span className={'author_name'}>
            <img src={Author.photo} />
            {Author.name}
          </span>
        </>
      )}
      {Date && <span className={'date'}>{Date}</span>}
      {ReadTime && <span className={'readTime'}>{ReadTime}</span>}
    </BlogDataStyles>
  );
};
