import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../../GlobalStyles";

export const BlogDataStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  
  span {
    font-size: 16px;
    line-height: 1;
    font-family: poppins;
    color: ${colors.UILightSecondary};
    display: flex;
    align-items: center;
    margin: 8px 0 0 0;
    
    img{
      margin-right: 8px;
    }

    &.author_name{
      font-family: poppins;
      color: ${colors.primary};
    }

    &.category{
      font-family: poppins;
    }
    
    &.date,
    &.readTime{
      text-transform: uppercase;
    }
    
    &.category{
      margin-left: 0;
      text-transform: uppercase;
      color: ${colors.highlight};
      &:after{
        display: none;
      }
    }
    
    &:last-of-type{
      &:after{
        display: none;
      }
    }
  }

  img {
    height: 26px;
    border-radius: 4px;
  }


  @media (min-width: ${mediaQueriesSizes.md}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    span{
      font-size: 17px;
      margin: 0 8px 0 0;
      &:after{
        content:"";
        display: inline-block;
        height: 20px;
        width: 1px;
        background:${colors.UILightSecondary};
        margin-left: 8px;
      }
    }

  }
`;