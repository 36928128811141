import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../../GlobalStyles";

export const SocialShareStyles = styled.div`
  font-family: poppins;
  color: ${colors.primary};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    text-align: right;
    align-items: center;
    justify-content: end;
    flex-direction: row;
  }


  a {
    display: inline-flex;
    border: solid 1px ${colors.UILightBordersSecondary};
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    align-items: center;
    margin-bottom: 16px;
    img {
      height: 16px;
      margin-right: 6px;
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin-left: 16px;
      margin-bottom: 0;
    }
  }

`;
